import React, { Component, useState, useRef, useEffect, useCallback } from 'react'
import {
  BrowserRouter as Router,
  generatePath,
  Switch,
  Route,
  Link,
  useParams,
  useNavigate,
  useLocation
} from "react-router-dom";
import Header from '../Header';
import Menu from '../Menu';
import Dashboard from '../Dashboard';
import Footer from '../Footer';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import osm from "../osm-providers";
import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import SensorGoruntule from './SensorGoruntule';
import GatewayGoruntule from './GatewayGoruntule';
import axios from 'axios';
import Paginations from "../Paginations";
import 'moment-timezone';
import moment from 'moment';
import localization from 'moment/locale/tr';
import GoogleFontLoader from 'react-google-font-loader';
import { Toggle } from '../components/ToggleButton';


export default function Anasayfa() {

  const setSensorButton = state => {
    console.log("Toggled:", state)
    setSensorToggle(state);
    console.log(sensordata.data);

  }
  const setGatewayButton = state => {
    console.log("Toggled:", state)
    setGatewayToggle(state);
    console.log(gatewaydata.data);
  }

  const [sensorToggle, setSensorToggle] = useState(true);
  const [gatewayToggle, setGatewayToggle] = useState(true);


  const navigate = useNavigate();
  const token = window.localStorage.getItem("token");
  const username = window.localStorage.getItem("username");
  const type = window.localStorage.getItem("type");

  const [sensorinfo, setSensorInfo] = useState();
  const [gatewayinfo, setGatewayInfo] = useState();
  const [uyariinfo, setUyariInfo] = useState();

  const [uyarikayitsayisi, setUyariKayitSayisi] = useState("");
  const [sensorkayitsayisi, setSensorKayitSayisi] = useState("");
  const [gatewaykayitsayisi, setGatewayKayitSayisi] = useState("");

  const ZOOM_LEVEL = 9;
  const mapRef = useRef();

  const gatewayIcon = new L.Icon({
    iconUrl: require('../images/gatewayicon.png'),
    iconSize: [40, 40],
    iconAnchor: [17, 46], //[left/right, top/bottom]
    popupAnchor: [0, -46], //[left/right, top/bottom]
  });

  const sensorIcon = new L.Icon({
    iconUrl: require('../images/sensoricon.png'),
    iconSize: [40, 40],
    iconAnchor: [17, 46], //[left/right, top/bottom]
    popupAnchor: [0, -46], //[left/right, top/bottom]
  });

  const sensorBosIcon = new L.Icon({
    iconUrl: require('../images/sensoricon.png'),
    iconSize: [40, 40],
    iconAnchor: [17, 46], //[left/right, top/bottom]
    popupAnchor: [0, -46], //[left/right, top/bottom]
  });


  const [sensordata, setSensorData] = useState({ data: [] });
  const [gatewaydata, setGatewayData] = useState({ data: [] });
  const [uyaridata, setUyariData] = useState({ data: [] });

  const SENSOR_URL = "https://panel.polepuhu.com/phpcnnfolder/api/read_sensor_last.php";
  const GATEWAY_URL = "https://panel.polepuhu.com/phpcnnfolder/api/read_gateway.php";
  const DELETE_SENSOR_URL = "https://panel.polepuhu.com/phpcnnfolder/api/delete_sensor.php";
  const DELETE_GATEWAY_URL = "https://panel.polepuhu.com/phpcnnfolder/api/delete_gateway.php";
  const SENSOR_UYARI_URL = "https://panel.polepuhu.com/phpcnnfolder/api/read_sensor_uyari.php";
  const UYARI_SENSOR_UPDATE_URL = "https://panel.polepuhu.com/phpcnnfolder/api/update_sensor_uyari.php";
  const UPDATE_ONCEKI_KAYIT_URL = "https://panel.polepuhu.com/phpcnnfolder/api/update_onceki_kayit.php";
  const UPDATE_BIRAYONCEKI_TARIH_URL = "https://panel.polepuhu.com/phpcnnfolder/api/update_birayonceki_tarih.php";

  const [valuesensorbuttonClick, setValueSensorButtonClick] = useState("");
  const [egimxbackground, setEgimXBackground] = useState("");

  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(() => {

    const tarihsaatbugün = new Date().toLocaleString();
    console.log("TARİH SAAT BUGÜN", tarihsaatbugün);

    var today = new Date();
    const anliksaat = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    console.log("ANLIK SAAT", anliksaat);
    const buguntarih = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + '' + " 10:00:00";
    console.log("buguntarih", buguntarih);

    //YESTERDAY
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    function padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
      return [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('-');
    }
    //YESTERDAY

    //TARIHBIRAY

    const tarihbiray = new Date();
    tarihbiray.setDate(tarihbiray.getDate() - 30);
    console.log("TARIHBIRAY", tarihbiray);

    var yesterdaywithtime = formatDate(yesterday) + " 23:59:00";
    var tarihbirayonceki = formatDate(tarihbiray) + " 10:00:00";

    var yesterdaytimeten = formatDate(yesterday) + " 10:00:00";

    console.log("TARIHBIRAYONCEKI = ", tarihbirayonceki);

    if (anliksaat > "10:00:00") {
      console.log("ANLIK SAAT büyüktür");
      updateoncekikayit(yesterdaywithtime);
      updatebirayoncekitarih(tarihbirayonceki);
    }
    else {
      updateoncekikayit(yesterdaytimeten);
      console.log("ANLIK SAAT küçüktür");
    }

    const res2 = {
      data: [
        {
          id: "0",
          g_id: "",
          ad: "",
          enlem: "",
          boylam: "",
          cbskod: "",
          dcins: "",
          dtip: "",
          egim_x: "",
          egim_y: "",
          egim_z: "",
          sicaklik: "",
          batarya: "",
          gzaman: "",
          sicaklik: "",
          once: "",
          uyari: "",
          deleted: "",
        }
      ],
    };

    axios.post(SENSOR_UYARI_URL).then(response => {

      if ("Kayit Bulunamadi" != response.data) {
        setUyariData(response.data);
        setUyariKayitSayisi(response.data.data.length);
      }
      else {
        setUyariData(res2);
        setUyariKayitSayisi("0");
      }

    });

    axios.post(SENSOR_URL).then(response => {

      if ("Kayit Bulunamadi" != response.data) {
        var sensordataTMP = { data: [] };
        //filter sensors with empty 'ad' field
        for (var i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].ad != "") {
            sensordataTMP.data.push(response.data.data[i]);
          }
        }
        setSensorData(sensordataTMP);
        setSensorKayitSayisi(response.data.data.length);
        console.log("SENSOR_DATASI", response.data);
        //add sensors has ':1' in their 'ad' field to the gatewaydata
        var gatewaydataTMP = { data: [] };

        for (var i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].ad.includes(":1")) {
            gatewaydataTMP.data.push(response.data.data[i]);
          }
        }
        setGatewayData(gatewaydataTMP);
      }
      else {
        setSensorData(res2);
        setSensorKayitSayisi("0");
        console.log("SENSOR_DATASI.DATA", sensordata.data);
        setGatewayData(res2);
        setGatewayKayitSayisi("0");
        console.log("GATEWAY_DATASI.DATA", gatewaydata.data);
      }

    });

    // axios.post(GATEWAY_URL).then(response => {
    //   if ("Kayit Bulunamadi" != response.data) {
    //     setGatewayData(response.data);
    //     setGatewayKayitSayisi(response.data.data.length);
    //   } else {
    //     setGatewayData(res2);
    //     setGatewayKayitSayisi("0");
    //     console.log("GATEWAY_DATASI.DATA", gatewaydata.data);
    //   }
    // });

  }, []);

  const updateoncekikayit = (once) => {

    axios.post(UPDATE_ONCEKI_KAYIT_URL, {
      once: once
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(response => {
        console.log("UPDATED ONCEKI KAYIT");
      })
      .catch(error => {
        console.log(error);
      });
  }

  const updatebirayoncekitarih = (e) => {

    axios.post(UPDATE_BIRAYONCEKI_TARIH_URL, {
      biray: e
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(response => {

      })
      .catch(error => {
      });
  }

  const handleSensorUyariDelete = (ad) => {

    console.log("DELETE_CLICKED");

    axios.post(UYARI_SENSOR_UPDATE_URL, {
      ad: ad
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(response => {
        console.log(response.data);
        setUyariInfo(ad + " NodeID 'li İş Emri Silindi");
        refreshPage();
      })
      .catch(error => {
        console.log(error);
        setUyariInfo(error);
      });
  }

  const handleSensorDelete = (ad) => {

    if ("" != ad) {
      axios.post(DELETE_SENSOR_URL, {
        ad: ad
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(response => {
          console.log(response.data);
          setSensorInfo(ad + " NodeID 'li sensör Silindi");
          refreshPage();
        })
        .catch(error => {
          console.log(error);
          setSensorInfo(error);
        });
    }
  }

  const handleGatewayDelete = (ad) => {
    axios
      .post(DELETE_GATEWAY_URL, { ad: ad }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then(response => {
        console.log(response.data);
        setGatewayInfo(ad + " NodeID 'li gateway Silindi");
        setGatewayData(prevData => {
          const newData = { ...prevData };
          newData.data = newData.data.filter(gateway => gateway.ad !== ad); // Silinen gateway'i veri listesinden çıkar
          return newData;
        });
      })
      .catch(error => {
        console.log(error);
        setGatewayInfo(error);
      });
  };
  console.log("ANASAYFA TOKEN", token);
  console.log("ANASAYFA USERNAME", username);

  const handleSensorClick = (id) => {
    mapRef.current._popup._closeButton.addEventListener('click', (event) => {
      event.preventDefault();
    })
    console.log("Sensor Marker Clicked");
    navigate("/SensorGoruntule/" + id, { replace: true });
  }

  const handleGatewayClick = (id) => {
    console.log("Gateway Marker Clicked");
    navigate("/GatewayGoruntule/" + id, { replace: true });
  }

  window.onload = function () {
    if (!window.location.hash) {
      window.location = window.location + '#';
      window.location.reload();
    }
  }

  const [currentUyariPage, setCurrentUyariPage] = useState(1);
  const [currentSensorPage, setCurrentSensorPage] = useState(1);
  const [currentGatewayPage, setCurrentGatewayPage] = useState(1);

  let LIMIT = 5;

  const onUyariPageChanged = useCallback(
    (event, page) => {
      event.preventDefault();
      setCurrentUyariPage(page);
    },
    [setCurrentUyariPage]
  );

  const currentuyaridata = uyaridata.data ? uyaridata.data.slice(
    (currentUyariPage - 1) * LIMIT,
    (currentUyariPage - 1) * LIMIT + LIMIT
  ) : [{ data: {} }];

  console.log("CURRENT_UYARI_DATA", currentuyaridata);

  const onSensorPageChanged = useCallback(
    (event, page) => {
      event.preventDefault();
      setCurrentSensorPage(page);
    },
    [setCurrentSensorPage]
  );

  const currentsensordata = sensordata.data.slice(
    (currentSensorPage - 1) * LIMIT,
    (currentSensorPage - 1) * LIMIT + LIMIT
  );

  const onGatewayPageChanged = useCallback(
    (event, page) => {
      event.preventDefault();
      setCurrentGatewayPage(page);
    },
    [setCurrentGatewayPage]
  );

  const currentgatewaydata = gatewaydata.data.slice(
    (currentGatewayPage - 1) * LIMIT,
    (currentGatewayPage - 1) * LIMIT + LIMIT
  );


  const handleEgimXBgColor = (value) => {
    const value1 = value.charAt(0).toString();

    if (0 == parseInt(value1)) { return ""; }
    else if (1 == parseInt(value1)) { return "#63BE7B"; }
    else if (2 == parseInt(value1)) { return "#B1D47F"; }
    else if (3 == parseInt(value1)) { return "#FFEB84"; }
    else if (4 == parseInt(value1)) { return "#F8696B"; }
    else if (5 == parseInt(value1)) { return "#FF0000"; }
  }

  const handleEgimYBgColor = (value) => {
    const value1 = value.charAt(1).toString();

    if (0 == parseInt(value1)) { return ""; }
    else if (1 == parseInt(value1)) { return "#63BE7B"; }
    else if (2 == parseInt(value1)) { return "#B1D47F"; }
    else if (3 == parseInt(value1)) { return "#FFEB84"; }
    else if (4 == parseInt(value1)) { return "#F8696B"; }
    else if (5 == parseInt(value1)) { return "#FF0000"; }
  }

  const handleEgimZBgColor = (value) => {
    const value1 = value.charAt(2).toString();

    if (0 == parseInt(value1)) { return "#999999"; }
    else if (1 == parseInt(value1)) { return "#63BE7B"; }
    else if (2 == parseInt(value1)) { return "#B1D47F"; }
    else if (3 == parseInt(value1)) { return "#FFEB84"; }
    else if (4 == parseInt(value1)) { return "#F8696B"; }
    else if (5 == parseInt(value1)) { return "#FF0000"; }
  }

  const handleKonumBgColor = (value) => {
    const value1 = value.charAt(3).toString();

    if (1 == parseInt(value1)) { return "#FF0000"; }
    else { return ''; }
  }

  const handleSicaklikColor = (value) => {
    const value1 = value.charAt(4).toString();

    if (1 == parseInt(value1)) { return "#FF0000"; }
    else { return ''; }
  }

  const handleBataryaColor = (value) => {
    const value1 = value.charAt(5).toString();

    if (1 == parseInt(value1)) { return "#FF0000"; }
    else { return ''; }
  }

  const isemrilistesi = () => {

    if (0 < currentuyaridata.length) {
      
      return (

        currentuyaridata.map((sensor) => {
          if(sensor.deleted!="1"){

            console.log("Currentuyaridatalength/LIMIT = ", currentuyaridata.length / LIMIT);

            // Function //
            const uyarikontrol = () => {
              {
                const value = sensor.uyari;

                const value1 = value.charAt(0).toString();
                const value2 = value.charAt(1).toString();
                const value3 = value.charAt(2).toString();
                const value4 = value.charAt(3).toString();
                const value5 = value.charAt(4).toString();
                const value6 = value.charAt(5).toString();
                var returnvalue = "";

                if (0 < value1) { returnvalue += "+ Eğim_X"; }
                if (0 < value2) { returnvalue += " + Eğim_Y"; }
                if (0 < value3) { returnvalue += " + Eğim_Z"; }
                if (1 == value4) { returnvalue += " + Konum"; }
                if (1 == value5) { returnvalue += " + Sıcaklık"; }
                if (1 == value6) { returnvalue += " + Pil Gerilimi"; }

                if (0 == value1 && 0 == value2 && 0 == value3 && 0 == value4 && 0 == value5 && 0 == value6) {
                  return "";
                }

                return returnvalue;
              }
            }
            if ("" != sensor.ad && "" != sensor.id) {
              if ("" == valuesensorbuttonClick) {
                window.$('#modal-danger').modal('show');
              }
              return (
                <><div class="modal fade" id="modal-danger">
                  <div class="modal-dialog">
                    <div class="modal-content bg-danger">
                      <div class="modal-header">
                        <h4 class="modal-title">Uyarı</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body" key={sensor.id}>
                        <p>İş Emri Listesi'nde Uyarı Bulunmaktadır. Lütfen Kontrol Ediniz..!</p>
                      </div>
                      <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-outline-light" data-dismiss="modal">Kapat</button>
                      </div>
                    </div>
                  </div>
                </div>
                  <tbody>
                    <tr key={sensor.id}>
                      <td>{uyarikontrol()}</td>
                      <td>{sensor.g_id}</td>
                      <td>{sensor.ad}</td>
                      <td style={{ background: handleKonumBgColor(sensor.uyari), textAlign: 'center' }}>{sensor.enlem}</td>
                      <td style={{ background: handleKonumBgColor(sensor.uyari), textAlign: 'center' }}>{sensor.boylam}</td>
                      <td style={{ background: handleEgimXBgColor(sensor.uyari), textAlign: 'center' }}>{sensor.uyari.charAt(0).toString() + '°'}</td>
                      <td style={{ background: handleEgimYBgColor(sensor.uyari), textAlign: 'center' }}>{sensor.uyari.charAt(1).toString() + '°'}</td>
                      <td style={{ background: handleEgimZBgColor(sensor.uyari), textAlign: 'center' }}>{sensor.uyari.charAt(2).toString() + '°'}</td>
                      <td style={{ background: handleSicaklikColor(sensor.uyari), textAlign: 'center' }}>{sensor.sicaklik}</td>
                      <td style={{ background: handleBataryaColor(sensor.uyari), textAlign: 'center' }}>{sensor.batarya}</td>
                      <td>{moment(sensor.gzaman).format('DD-MM-YYYY HH:mm:ss')}</td>
                      { type == 1 && 
                        <td><button class="btn btn-danger" value={sensor.ad} data-toggle="modal" data-target="#modal-dangerrr" onClick={() => setValueSensorButtonClick(sensor.ad)}>Sil</button></td>
                      }
                      <td><Link to={{ pathname: `/SensorGoruntule/${sensor.id}`, state: { sensors: sensor } }}>
                        <a class="btn btn-success" >Görüntüle</a></Link></td>
                    </tr>
                  </tbody></>
              )
            }
          }
        })
      )
    }
    else {
      return (
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      )
    }
  }

  const getUyariPagination = () => {
    if (0 < currentuyaridata.length) {
      return (
        <Paginations
          totalRecords={(currentuyaridata.length / LIMIT) + 1 * uyaridata.data.length}
          pageLimit={LIMIT}
          pageNeighbours={2}
          onPageChanged={onUyariPageChanged}
          currentPage={currentUyariPage}
        />
      )
    }
  }
  const getSensorPagination = () => {
    if (0 < sensordata.data.length) {
      return (
        <Paginations
          totalRecords={(currentsensordata.length / LIMIT) + 1 * sensordata.data.length}
          pageLimit={LIMIT}
          pageNeighbours={2}
          onPageChanged={onSensorPageChanged}
          currentPage={currentSensorPage}
        />
      )
    }
  }
  const getGatewayPagination = () => {
    if (0 < gatewaydata.data.length) {
      return (
        <Paginations
          totalRecords={(currentgatewaydata.length / LIMIT) + 1 * gatewaydata.data.length}
          pageLimit={LIMIT}
          pageNeighbours={2}
          onPageChanged={onGatewayPageChanged}
          currentPage={currentGatewayPage}
        />
      )
    }
  }

  const handleSensorUyariDeleteYesClicked = () => {

    console.log("YES CLICKED");
    if ("" != valuesensorbuttonClick) {
      handleSensorUyariDelete(valuesensorbuttonClick);
    }
  }

  const handleSensorDeleteYesClicked = () => {

    console.log("YES CLICKED");
    if ("" != valuesensorbuttonClick) {
      handleSensorDelete(valuesensorbuttonClick);
    }
  }

  const handleSensorDeleteNoClicked = () => {

    console.log("NO CLICKED");

  }

  const handleGatewayDeleteYesClicked = () => {

    console.log("YES CLICKED");
    if ("" != valuesensorbuttonClick) {
      handleGatewayDelete(valuesensorbuttonClick);
    }
  }

  const handleGatewayDeleteNoClicked = () => {
    console.log("NO CLICKED");
  }


  const sensorlistesi = () => {
    if (0 < currentsensordata.length) {
      return (
        currentsensordata.map((sensor) => {

          if ("" != sensor.g_id && "" != sensor.id  ) {
            return (
              <tbody>
                <tr key={sensor.id}>
                  <td>{sensor.g_id}</td>
                  <td>{sensor.ad}</td>
                  <td>{sensor.enlem}</td>
                  <td>{sensor.boylam}</td>
                  <td>{sensor.egim_x}</td>
                  <td>{sensor.egim_y}</td>
                  <td>{sensor.egim_z}</td>
                  <td>{sensor.sicaklik}</td>
                  <td>{sensor.batarya}</td>
                  <td>{moment(sensor.gzaman).format('DD-MM-YYYY HH:mm:ss')}</td>
                  { type == 1 && 
                    <td><button class="btn btn-danger" value={sensor.ad} data-toggle="modal" data-target="#modal-dangerr" onClick={() => setValueSensorButtonClick(sensor.ad)}>Sil</button></td>
                  }
                  <td><Link to={{ pathname: `/SensorGoruntule/${sensor.id}`, state: { sensors: sensor } }}>
                    <a class="btn btn-success" >Görüntüle</a></Link></td>
                </tr>
              </tbody>
            );
          }
        }))
    }
    else {
      return (
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      )
    }
  }

  const gatewayguncellemekontrol = (ad) => {
    if (0 < currentsensordata.length) {
      var returnvalue = "";

      sensordata.data.map((sensor) => {

        const value = sensor.ad;
        const value1 = value.charAt(value.length - 1).toString();

        if (ad == sensor.ad) {
          if ("1" == value1) {
            returnvalue += moment(sensor.gzaman).format('DD-MM-YYYY HH:mm:ss');
          }
        }
      }
      )
      return returnvalue;
    }
  }

  const gatewaylistesi = () => {
    if (0 < currentgatewaydata.length) {
      return (currentgatewaydata.map((gateway) => {
        
        return (
          <tbody>
            <tr>
              <td>{gateway.g_id}</td>
              <td>{gateway.ad}</td>
              <td>{gateway.enlem}</td>
              <td>{gateway.boylam}</td>
              <td>{gateway.cbskod}</td>
              <td>{gateway.dcins}</td>
              <td>{gateway.dtip}</td>
              <td>{gatewayguncellemekontrol(gateway.ad)}</td>
              { type == 1 && 
                <td><button class="btn btn-danger" value={gateway.ad} data-toggle="modal" data-target="#modal-dangerrrr" onClick={() => setValueSensorButtonClick(gateway.ad)}>Sil</button></td>
              } 
              <td><Link to={{ pathname: `/GatewayGoruntule/${gateway.id}`, state: { gatewaydata: gateway } }}>
                <a class="btn btn-success" >Görüntüle</a></Link></td>
            </tr>
          </tbody>
        );
      }))
    }
    else {
      return (
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      )
    }
  }



  if (null != token) {
    return (
      <div class="wrapper">
        <GoogleFontLoader
          fonts={[
            {
              font: 'Sofia Sans',
              weights: [400],
            },

          ]}
          subsets={['cyrillic-ext', 'greek']}
        />
        <Header />
        <Menu />
        <div  >
          <div style={{
            backgroundColor: "#F0F2F6",

          }} className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h5 style={{ color: '#001747', paddingTop: '1rem', fontFamily: 'Sofia Sans' }} className="m-0 right">Anasayfa</h5>
                  </div>
                  <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                      <a style={{ minWidth: '7rem', backgroundColor: 'white', color: '#1A142C', borderRadius: '5px', marginRight: '10px' }} className="nav-link active">
                        <li class="breadcrumb-item"><img src="../images/sensoricon.png" style={{ width: '20px', height: '20px' }} /><h6 style={{ fontSize: '15px', fontFamily: 'Sofia Sans' }} >Sensör</h6></li>
                        <Toggle toggled={true}
                          onClick={setSensorButton} />
                      </a>
                      <a style={{ minWidth: '7rem', backgroundColor: 'white', color: '#1A142C', borderRadius: '5px' }} className="nav-link active">
                        <li class="breadcrumb-item"><img src="../images/gatewayicon.png" style={{ width: '20px', height: '20px' }} /><h6 style={{ fontSize: '15px', fontFamily: 'Sofia Sans' }}>Gateway</h6></li>
                        <Toggle toggled={true}
                          onClick={setGatewayButton} />
                      </a>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <MapContainer
                      style={{
                        borderRadius: '20px',
                      }}
                      center={[40.3039540, 41.5321780]}
                      zoom={ZOOM_LEVEL}
                      ref={mapRef}
                    >
                      <TileLayer
                        url={osm.maptiler.url}
                        attribution={osm.maptiler.attribution}
                      />

                      {sensordata.data.map(sensor => {
                        if (sensorToggle == true)
                          return (
                            <Marker
                              key={sensor.id}
                              position={[sensor.enlem, sensor.boylam]}
                              icon={sensorIcon}
                              eventHandlers={{
                                click: () => {
                                  handleSensorClick(sensor.id);
                                },
                                mouseover: (event) => event.target.openPopup(),
                                mouseout: (event) => event.target.closePopup(),
                                popupopen: (event) => {
                                  event.popup._closeButton.removeAttribute("href");
                                  event.popup._closeButton.style.cursor = "none";
                                }
                              }}
                            >
                              <Popup closeButton>
                                {"Sensör: " + sensor.ad}
                                <br />
                                

                                {"\n CBS: " + sensor.cbskod} 
                              </Popup>
                            </Marker>
                          )
                      })}
                      {gatewaydata.data.map(gateWay => {
                        if (gatewayToggle == true)
                          return (
                            <Marker
                              eventHandlers={{
                                click: () => {
                                  handleGatewayClick(gateWay.id);
                                },
                                mouseover: (event) => event.target.openPopup(),
                                mouseout: (event) => event.target.closePopup(),
                              }}
                              key={gateWay.id}
                              position={[gateWay.enlem, gateWay.boylam]}
                              icon={gatewayIcon}
                            >
                              <Popup closeButton>
                                {"Gateway: " + gateWay.ad}
                                <br />
                                {"\n CBS: " + gateWay.cbskod}
                              </Popup>
                            </Marker>
                          )
                      })}

                    </MapContainer>
                  </div>
                </div>
              </div>
              <br></br>

              <div class="container-fluid">
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-header">
                        <h3 class="card-title">İş Emri Listesi</h3>
                      </div>
                      <div class="card-body table-responsive p-0">
                        <table id="tbl1" class="table table-hover text-nowrap">
                          <thead>
                            <tr>
                              <th>Uyarı</th>
                              <th>GatewayID</th>
                              <th>NodeID</th>
                              <th>Enlem</th>
                              <th>Boylam</th>
                              <th>Eğim_x</th>
                              <th>Eğim_y</th>
                              <th>Eğim_z</th>
                              <th>Sıcaklık</th>
                              <th>Batarya (V)</th>
                              <th>Son Güncelleme</th>
                              { type == 1 && 
                              <th>Sil</th>
                              }
                              <th>Görüntüle</th>
                            </tr>
                          </thead>
                          {isemrilistesi()}

                        </table>
                      </div>
                      <div>
                        <div className="pagination-wrapper" style={{ float: 'right', marginRight: '35px', marginTop: '5px' }}>
                          {getUyariPagination()}
                        </div>
                        <div className='dataTables_info' style={{ margin: '10px' }}>{uyarikayitsayisi} kayıttan gösteriliyor..</div>
                      </div>
                    </div>
                  </div>
                  <div>{uyariinfo}</div>
                </div>
              </div>

              <div class="container-fluid">
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-header">
                        <h3 class="card-title">Sensör Listesi</h3>
                      </div>
                      <div class="card-body table-responsive p-0">
                        <table id="tbl1" class="table table-hover text-nowrap">
                          <thead>
                            <tr>
                              <th>GatewayID</th>
                              <th>NodeID</th>
                              <th>Enlem</th>
                              <th>Boylam</th>
                              <th>Eğim_x</th>
                              <th>Eğim_y</th>
                              <th>Eğim_z</th>
                              <th>Sıcaklık</th>
                              <th>Batarya (V)</th>
                              <th>Güncelleme Zamanı</th>
                              { type == 1 && 
                              <th>Sil</th>
                              }
                              <th>Görüntüle</th>
                            </tr>
                          </thead>
                          {sensorlistesi()}
                          <tfoot>
                            <tr>
                              <th>GatewayID</th>
                              <th>NodeID</th>
                              <th>Enlem</th>
                              <th>Boylam</th>
                              <th>Eğim_x</th>
                              <th>Eğim_y</th>
                              <th>Eğim_z</th>
                              <th>Sıcaklık</th>
                              <th>Batarya (V)</th>
                              <th>Güncelleme Zamanı</th>
                              { type == 1 && 
                              <th>Sil</th>
                              }
                              <th>Görüntüle</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <div>
                        <div className="pagination-wrapper" style={{ float: 'right', marginRight: '35px', marginTop: '5px' }}>
                          {getSensorPagination()}
                        </div>
                        <div className='dataTables_info' style={{ margin: '10px' }}>{sensorkayitsayisi} kayıttan gösteriliyor..</div>
                      </div>
                    </div>
                  </div>
                  <div>{sensorinfo}</div>
                </div>
              </div>

              <div class="container-fluid">
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-header">
                        <h3 class="card-title">Gateway Listesi</h3>
                      </div>
                      <div class="card-body table-responsive p-0">
                        <table id="tbl1" class="table table-hover text-nowrap">
                          <thead>
                            <tr>
                              <th>GatewayID</th>
                              <th>Gateway Adı</th>
                              <th>Enlem</th>
                              <th>Boylam</th>
                              <th>Cbs Kodu</th>
                              <th>Direk Tipi</th>
                              <th>Direk Cinsi</th>
                              <th>Güncelleme Zamanı</th>
                              { type == 1 && 
                              <th>Sil</th>
                              }
                              <th>Görüntüle</th>
                            </tr>
                          </thead>
                          {gatewaylistesi()}
                          <tfoot>
                            <tr>
                              <th>GatewayID</th>
                              <th>Gateway Adı</th>
                              <th>Enlem</th>
                              <th>Boylam</th>
                              <th>Cbs Kodu</th>
                              <th>Direk Tipi</th>
                              <th>Direk Cinsi</th>
                              <th>Güncelleme Zamanı</th>
                              { type == 1 && 
                              <th>Sil</th>
                              }
                              <th>Görüntüle</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <div>
                        <div className="pagination-wrapper" style={{ float: 'right', marginRight: '35px', marginTop: '5px' }}>
                          {getGatewayPagination()}
                        </div>
                        <div className='dataTables_info' style={{ margin: '10px' }}>{gatewaykayitsayisi} kayıttan gösteriliyor..</div>
                      </div>
                    </div>
                  </div>
                  <div>{gatewayinfo}</div>
                </div>
              </div>
              <br></br>

            </div>
          </div>

          <div class="modal fade" id="modal-dangerr">
            <div class="modal-dialog">
              <div class="modal-content bg-danger">
                <div class="modal-header">
                  <h4 class="modal-title">Uyarı</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p> {valuesensorbuttonClick} NodeID'li Sensör'ü Tamamen <br></br>Silmek İstediğinize Emin Misiniz ?</p>
                </div>
                <div class="modal-footer justify-content-between">
                  <button type="button" class="btn btn-outline-light" data-dismiss="modal" onClick={handleSensorDeleteNoClicked}>Hayır</button>
                  <button type="button" class="btn btn-outline-light" data-dismiss="modal" onClick={handleSensorDeleteYesClicked}>Evet</button>
                </div>
              </div>
            </div>
          </div>

          <div class="modal fade" id="modal-dangerrr">
            <div class="modal-dialog">
              <div class="modal-content bg-danger">
                <div class="modal-header">
                  <h4 class="modal-title">Uyarı</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p> {valuesensorbuttonClick} NodeID'li Sensöre Ait Uyarıyı <br></br>Silmek İstediğinize Emin Misiniz ?</p>
                </div>
                <div class="modal-footer justify-content-between">
                  <button type="button" class="btn btn-outline-light" data-dismiss="modal" onClick={handleSensorDeleteNoClicked}>Hayır</button>
                  <button type="button" class="btn btn-outline-light" data-dismiss="modal" onClick={handleSensorUyariDeleteYesClicked}>Evet</button>
                </div>
              </div>
            </div>
          </div>

          <div class="modal fade" id="modal-dangerrrr">
            <div class="modal-dialog">
              <div class="modal-content bg-danger">
                <div class="modal-header">
                  <h4 class="modal-title">Uyarı</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p> {valuesensorbuttonClick} NodeID'li Gateway'i Tamamen<br></br>Silmek İstediğinize Emin Misiniz ?</p>
                </div>
                <div class="modal-footer justify-content-between">
                  <button type="button" class="btn btn-outline-light" data-dismiss="modal" onClick={handleGatewayDeleteNoClicked}>Hayır</button>
                  <button type="button" class="btn btn-outline-light" data-dismiss="modal" onClick={handleGatewayDeleteYesClicked}>Evet</button>
                </div>
              </div>
            </div>
          </div>

          <aside className="control-sidebar control-sidebar-dark">
          </aside>
        </div>
        <Footer />
      </div>
    );
  }
}