import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Giris from './pages/Giris';
import Anasayfa from './pages/Anasayfa';
import SensorEkle from './pages/SensorEkle';
import SensorGoruntule from './pages/SensorGoruntule';
import GatewayEkle from './pages/GatewayEkle';
import GatewayGoruntule from './pages/GatewayGoruntule';

import { BrowserRouter as Router, Routes, Route, Switch, Link, HashRouter } from "react-router-dom";
import BarChart from './components/BarChart'
import Update from './pages/Update';

ReactDOM.render(
  <HashRouter>
    <Routes>
      <Route path="/" element={<Giris />} />
      <Route path="/anasayfa/" element={<Anasayfa />} />
      <Route path="/sensorekle/" element={<SensorEkle />} />
      <Route path="/sensorgoruntule/:id/" element={<SensorGoruntule />} />
      <Route path="/gatewayekle/" element={<GatewayEkle />} />
      <Route path="/gatewaygoruntule/:id/" element={<GatewayGoruntule />} />

      {/* <Route path="/" element={<Giris />} />
      <Route path="/anasayfa/" element={<Update />} />
      <Route path="/sensorekle/" element={<Update />} />
      <Route path="/sensorgoruntule/:id/" element={<Update />} />
      <Route path="/gatewayekle/" element={<Update />} />
      <Route path="/gatewaygoruntule/:id/" element={<Update />} />
      <Route path="/update" element={<Update />} /> */}
    </Routes>
  </HashRouter>,
  document.getElementById('root')

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();