import React, { Component, useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import osm from "./osm-providers";
export default class Dashboard extends Component {
  render() {

    return (
<div>
  <div className="content-wrapper">
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Dashboard v3</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <MapContainer>
            <TileLayer
                                url={osm.maptiler.url}
                                attribution={osm.maptiler.attribution}
                            />         
            </MapContainer>
          </div>
        </div>
      </div>
    </div>
  </div>
  <aside className="control-sidebar control-sidebar-dark">
  </aside>
</div>

    )
  }
}
