import React, { Component, useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Header from '../Header';
import Menu from '../Menu';
import Dashboard from '../Dashboard';
import Footer from '../Footer';
import axios from 'axios';
import 'moment-timezone';
import moment from 'moment';
import localization from 'moment/locale/tr';

export default function GatewayGoruntule() {

    const { id } = useParams();
    console.log(id);
    const type = localStorage.getItem('type');

    const [sensorid, setSensorID] = useState("");
    const [g_id, setG_ID] = useState("");
    const [ad, setAd] = useState("");
    const [enlem, setEnlem] = useState("");
    const [boylam, setBoylam] = useState("");
    const [egim_x, setEgimX] = useState("");
    const [egim_y, setEgimY] = useState("");
    const [egim_z, setEgimZ] = useState("");
    const [sicaklik, setSicaklik] = useState("");
    const [batarya, setBatarya] = useState("");
    const [gzaman, setGZaman] = useState("");
    const [uyari, setUyari] = useState("");
    const [uyari_varyok, setUyariVarYok] = useState("");
    const [info, setInfo] = useState("");
    const [cbskod, setCbsKod] = useState("");
    const [dtip, setDTip] = useState("");
    const [dcins, setDCins] = useState("");
    const [sensorad, setSensorAd] = useState("");


    const handleG_ID = (event) => { setG_ID(event.target.value); };
    const handleAd = (event) => { setAd(event.target.value); };
    const handleEnlem = (event) => { setEnlem(event.target.value); };
    const handleBoylam = (event) => { setBoylam(event.target.value); };
    const handleEgimX = (event) => { setEgimX(event.target.value); };
    const handleEgimY = (event) => { setEgimY(event.target.value); };
    const handleEgimZ = (event) => { setEgimZ(event.target.value); };
    const handleSicaklik = (event) => { setSicaklik(event.target.value); };
    const handleBatarya = (event) => { setBatarya(event.target.value); };
    const handleGZaman = (event) => { setGZaman(event.target.value); };
    const handleUyari = (event) => { setUyari(event.target.value); };
    const handleCbsKod = (event) => { setCbsKod(event.target.value); };
    const handleDTip = (event) => { setDTip(event.target.value); };
    const handleDCins = (event) => { setDCins(event.target.value); };

    const [gatewaydata, setGatewayData] = useState({ data: [] });
    const SENSOR_URL = "https://panel.polepuhu.com/phpcnnfolder/api/read_single_sensor.php?id=" + id;
    const UPDATE_SENSOR_URL = "https://panel.polepuhu.com/phpcnnfolder/api/update_sensor.php";

    useEffect(() => {
        axios.post(SENSOR_URL).then(response => {

            if ("hata" != response.data) {
                setGatewayData(response.data);
                setSensorID(response.data.id);

                setG_ID(response.data.g_id);
                setAd(response.data.ad);
                setEnlem(response.data.enlem);
                setBoylam(response.data.boylam);
                setEgimX(response.data.egim_x);
                setEgimY(response.data.egim_y);
                setEgimZ(response.data.egim_z);
                setSicaklik(response.data.sicaklik);
                setBatarya(response.data.batarya);
                setGZaman(response.data.gzaman);
                setCbsKod(response.data.cbskod);
                setDTip(response.data.dtip);
                setDCins(response.data.dcins);
                setUyari(response.data.uyari);

                setSensorAd(response.data.ad);
            }
        });
    }, []);

    const handleSubmit = async () => {


        if ("" != g_id) {
            axios.post(UPDATE_SENSOR_URL, {
                id: id,
                g_id: g_id,
                ad: ad,
                enlem: enlem,
                boylam: boylam,
                egim_x: egim_x,
                egim_y: egim_y,
                egim_z: egim_z,
                sicaklik: sicaklik,
                batarya: batarya,
                gzaman: gzaman,
                uyari: uyari,
                cbskod: cbskod,
                dtip: dtip,
                dcins: dcins
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
                .then(response => {
                    console.log(response.data);
                    setInfo("Güncellendi");
                })
                .catch(error => {
                    console.log(error);
                    setInfo(error);
                });
        }
    }
    const token = window.localStorage.getItem("token");
    console.log("USER TOKEN GATEWAYGORUNTULE", token);

    if (null != token) {
        return (
            <div class="wrapper">
                <Header />
                <Menu />
                <div className="content-wrapper">
                    <br></br>
                    <section class="content">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <h3 class="card-title">Gateway Görüntüle</h3>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div className="col-md-5">
                                                        <div class="row">
                                                            <div className="content">
                                                                <div className="container-fluid">
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>GatewayID:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className="form-control" onChange={handleG_ID} value={g_id} placeholder={gatewaydata.g_id} name="GATEWAYID" disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>Gateway Adı:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className="form-control" onChange={handleAd} value={ad} placeholder={gatewaydata.ad} name="GATEWAYADI" disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>Enlem:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className="form-control" onChange={handleEnlem} value={enlem} placeholder={gatewaydata.enlem} name="ENLEM" disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>Boylam:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className="form-control" onChange={handleBoylam} value={boylam} placeholder={gatewaydata.boylam} name="BOYLAM" disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>Cbs Kodu:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    type == 1 &&
                                                                                    <input type="text" className="form-control" onChange={handleCbsKod} value={cbskod} placeholder={gatewaydata.cbskod} name="CBSKODU" />
                                                                                }
                                                                                {
                                                                                    type == 0 &&
                                                                                    <input type="text" className="form-control" onChange={handleCbsKod} value={cbskod} placeholder={gatewaydata.cbskod} name="CBSKODU" disabled />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>Direk Cinsi:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                {   type == 1 &&
                                                                                <select className="custom-select form-control" onChange={handleDCins} value={dcins} placeholder={gatewaydata.dcins} name="DIREKCINSI">
                                                                                    <option>Demir Direk</option>
                                                                                    <option>Beton Direk</option>
                                                                                    <option>Ağaç Direk</option>
                                                                                </select>}
                                                                                {   type == 0 &&
                                                                                <select className="custom-select form-control" onChange={handleDCins} value={dcins} placeholder={gatewaydata.dcins} name="DIREKCINSI" disabled>
                                                                                    <option>Demir Direk</option>
                                                                                    <option>Beton Direk</option>
                                                                                    <option>Ağaç Direk</option>
                                                                                </select>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>Direk Tipi:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                {   type == 1 &&
                                                                                    <input type="text" className="form-control" onChange={handleDTip} value={dtip} placeholder={gatewaydata.dtip} name="DIREKTIPI" />
                                                                                }
                                                                                {   type == 0 &&
                                                                                    <input type="text" className="form-control" onChange={handleDTip} value={dtip} placeholder={gatewaydata.dtip} name="DIREKTIPI" disabled />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>Güncelleme Zamanı:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                {   type == 1 &&
                                                                                    <input type="text" className="form-control" onChange={handleGZaman} value={moment(gzaman).format('DD-MM-YYYY HH:mm:ss')} placeholder={moment(gatewaydata.guncellemezamani).format('DD-MM-YYYY HH:mm:ss')} name="GUNCELLEMEZAMANI" />
                                                                                }
                                                                                {   type == 0 &&
                                                                                    <input type="text" className="form-control" onChange={handleGZaman} value={moment(gzaman).format('DD-MM-YYYY HH:mm:ss')} placeholder={moment(gatewaydata.guncellemezamani).format('DD-MM-YYYY HH:mm:ss')} name="GUNCELLEMEZAMANI" disabled />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {   type == 1 &&
                                                                        <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <div class="input-group">
                                                                                <button class="btn btn-warning" style={{ marginTop: 10, width: 100 }}>Güncelle</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div>{info}</div>
                        </div>
                    </section>
                </div>
                <aside className="control-sidebar control-sidebar-dark">
                </aside>
                <Footer />
            </div>
        );
    }
}
