import React, { Component, useState, useRef, useEffect } from 'react'
import Header from '../Header';
import Menu from '../Menu';
import Dashboard from '../Dashboard';
import Footer from '../Footer';
import axios from 'axios';
import 'moment-timezone';
import moment from 'moment';
import GoogleFontLoader from 'react-google-font-loader';
import LanguageIcon from '@mui/icons-material/Language';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import TextRotationAngleupIcon from '@mui/icons-material/TextRotationAngleup';
import TextRotationAngledownIcon from '@mui/icons-material/TextRotationAngledown';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import RampLeftIcon from '@mui/icons-material/RampLeft';
import TitleIcon from '@mui/icons-material/Title';
import localization from 'moment/locale/tr';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    //change placeholder color in inputTwo
    inputTwo: {
        border: '1px solid #D1D8E4',
        borderRadius: '1rem',
        '&::placeholder': {
            color: '#8D99B8',
        },
    },

});
export default function SensorEkle() {

    const ADDSENSOR_URL = "https://panel.polepuhu.com/phpcnnfolder/api/insert_sensor.php";

    const [info, setInfo] = useState("");
    const [g_id, setG_ID] = useState("");
    const [ad, setAd] = useState("");
    const [enlem, setEnlem] = useState("");
    const [boylam, setBoylam] = useState("");
    const [egim_x, setEgimX] = useState("");
    const [egim_y, setEgimY] = useState("");
    const [egim_z, setEgimZ] = useState("");
    const [sicaklik, setSicaklik] = useState("");
    const [batarya, setBatarya] = useState("");
    const [gzaman, setGZaman] = useState("");
    const [cbskod, setCbsKod] = useState("");
    const [dtip, setDTip] = useState("");
    const [dcins, setDCins] = useState("");

    const handleG_ID = (event) => { setG_ID(event.target.value); };
    const handleAd = (event) => { setAd(event.target.value); };
    const handleEnlem = (event) => { setEnlem(event.target.value); };
    const handleBoylam = (event) => { setBoylam(event.target.value); };
    const handleEgimX = (event) => { setEgimX(event.target.value); };
    const handleEgimY = (event) => { setEgimY(event.target.value); };
    const handleEgimZ = (event) => { setEgimZ(event.target.value); };
    const handleSicaklik = (event) => { setSicaklik(event.target.value); };
    const handleBatarya = (event) => { setBatarya(event.target.value); };
    const handleCbsKod = (event) => { setCbsKod(event.target.value); };
    const handleDTip = (event) => { setDTip(event.target.value); };
    const handleDCins = (event) => { setDCins(event.target.value); };

    const tarihsaatbugün = new Date().toLocaleString();
    console.log("MOMENT = ", moment(new Date()).format("DD-MM-YYYY H:m:s"));

    const handleSubmit = async () => {

        console.log("GATEWAY_ID", g_id);
        console.log("ad", ad);
        console.log("enlem", enlem);
        console.log("boylam", boylam);
        console.log("egim_x", egim_x);
        console.log("egim_y", egim_y);
        console.log("egim_z", egim_z);
        console.log("sicaklik", sicaklik);
        console.log("batarya_gerilimi", batarya);
        console.log("cbskodu", cbskod);
        console.log("direktipi", dtip);
        console.log("direkcinsi", dcins);

        console.log("tarihsaatbugün = ", tarihsaatbugün);

        if ("" != g_id) {
            axios.post(ADDSENSOR_URL, {
                g_id: g_id,
                ad: ad,
                enlem: enlem,
                boylam: boylam,
                egim_x: egim_x,
                egim_y: egim_y,
                egim_z: egim_z,
                sicaklik: sicaklik,
                batarya: batarya,
                cbskod: cbskod,
                dtip: dtip,
                dcins: dcins,
                gzaman: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                uyari: "0000",
                once: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                biray: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
                .then(response => {
                    console.log(response.data);
                    setInfo("Eklendi");
                })
                .catch(error => {
                    console.log(error);
                    setInfo(error);
                });
        }
    }
    const token = window.localStorage.getItem("token");
    console.log("USER TOKEN SENSOREKLE", token);
    const classes = useStyles();
    if (null != token) {

        return (
            <div class="wrapper">
                <GoogleFontLoader
                    fonts={[
                        {
                            font: 'Sofia Sans',
                            weights: [400],
                        },

                    ]}
                    subsets={['cyrillic-ext', 'greek']}
                />
                <Header />
                <Menu />
                <div style={{
                    backgroundColor: "#F0F2F6",
                    height: "100vh",
                }} className="content-wrapper">
                    <br></br>
                    <section class="content">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <h3 style={{ fontWeight: 'bold', fontSize: '20px', fontFamily: 'Sofia Sans' }} class="card-title">Sensör Ekle</h3>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div className="col-lg12">
                                                        <div class="row">
                                                            <div className="content">

                                                                <div className="container-fluid">
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-6">
                                                                            <label style={{ color: '#001747', fontFamily: 'Sofia Sans' }} >Gateway ID:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div style={{ backgroundColor: '#D3E5FD' }} class="input-group-text">
                                                                                        <PodcastsIcon />
                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className={classes.inputTwo + ' form-control'} onChange={handleG_ID} value={g_id} placeholder="GatewayID" name="GATEWAYID" disabled />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <label style={{ color: '#001747', fontFamily: 'Sofia Sans' }}>Sensör Adı:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div style={{ backgroundColor: '#D3E5FD' }} class="input-group-text">
                                                                                        <PodcastsIcon />
                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className={classes.inputTwo + ' form-control'} onChange={handleAd} value={ad} placeholder="Sensör Adı" name="SENSORADI" disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-6">
                                                                            <label style={{ color: '#001747', fontFamily: 'Sofia Sans' }}>Enlem:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div style={{ backgroundColor: '#D3E5FD' }} class="input-group-text">
                                                                                        <LanguageIcon />                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className={classes.inputTwo + ' form-control'} onChange={handleEnlem} value={enlem} placeholder="Enlem" name="ENLEM" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <label style={{ color: '#001747', fontFamily: 'Sofia Sans' }}>Boylam:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div style={{ backgroundColor: '#D3E5FD' }} class="input-group-text">
                                                                                        <LanguageIcon />
                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className={classes.inputTwo + ' form-control'} onChange={handleBoylam} value={boylam} placeholder="Boylam" name="BOYLAM" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-6">
                                                                            <label style={{ color: '#001747', fontFamily: 'Sofia Sans' }}>Eğim_X:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div style={{ backgroundColor: '#D3E5FD' }} class="input-group-text">
                                                                                        <TextRotationAngledownIcon />                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className={classes.inputTwo + ' form-control'} onChange={handleEgimX} value={egim_x} placeholder="Eğim_X" name="EGIMX" />
                                                                            </div>
                                                                        </div>  <div className="col-lg-6">
                                                                            <label style={{ color: '#001747', fontFamily: 'Sofia Sans' }}>Eğim_Y:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div style={{ backgroundColor: '#D3E5FD' }} class="input-group-text">
                                                                                        <TextRotationAngleupIcon />                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className={classes.inputTwo + ' form-control'} onChange={handleEgimY} value={egim_y} placeholder="Eğim_Y" name="EGIMY" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-6">
                                                                            <label style={{ color: '#001747', fontFamily: 'Sofia Sans' }}>Eğim_Z:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div style={{ backgroundColor: '#D3E5FD' }} class="input-group-text">
                                                                                        <TextRotationAngleupIcon />
                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className={classes.inputTwo + ' form-control'} onChange={handleEgimZ} value={egim_z} placeholder="Eğim_Z" name="EGIMZ" />
                                                                            </div>
                                                                        </div> <div className="col-lg-6">
                                                                            <label style={{ color: '#001747', fontFamily: 'Sofia Sans' }}>Sıcaklık:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div style={{ backgroundColor: '#D3E5FD' }} class="input-group-text">
                                                                                        <DeviceThermostatIcon />                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className={classes.inputTwo + ' form-control'} onChange={handleSicaklik} value={sicaklik} placeholder="Sıcaklık" name="SICAKLIK" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-6">
                                                                            <label style={{ color: '#001747', fontFamily: 'Sofia Sans' }}>Batarya Gerilimi:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div style={{ backgroundColor: '#D3E5FD' }} class="input-group-text">
                                                                                        <BatteryChargingFullIcon />                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className={classes.inputTwo + ' form-control'} onChange={handleBatarya} value={batarya} placeholder="Batarya Gerilimi" name="BATARYAGERILIMI" />
                                                                            </div>
                                                                        </div><div className="col-lg-6">
                                                                            <label style={{ color: '#001747', fontFamily: 'Sofia Sans' }}>Cbs Kodu:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div style={{ backgroundColor: '#D3E5FD' }} class="input-group-text">
                                                                                        <QrCode2Icon />                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className={classes.inputTwo + ' form-control'} onChange={handleCbsKod} value={cbskod} placeholder="Cbs Kodu" name="CBSKODU" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-6">
                                                                            <label style={{ color: '#001747', fontFamily: 'Sofia Sans' }}>Direk Cinsi:</label>
                                                                            <div class="input-group">

                                                                                <select className="custom-select form-control" onChange={handleDCins} value={dcins} placeholder="Direk Cinsi" name="DIREKCINSI">
                                                                                    <option>Demir Direk</option>
                                                                                    <option>Beton Direk</option>
                                                                                    <option>Ağaç Direk</option>
                                                                                </select>
                                                                            </div>
                                                                        </div> <div className="col-lg-6">
                                                                            <label style={{ color: '#001747', fontFamily: 'Sofia Sans' }}>Direk Tipi:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div style={{ backgroundColor: '#D3E5FD' }} class="input-group-text">
                                                                                        <RampLeftIcon />                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className={classes.inputTwo + ' form-control'} onChange={handleDTip} value={dtip} placeholder="Direk Tipi" name="DIREKTIPI" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <div class="input-group" style={{ right: '0px' }}>
                                                                                <button class="btn btn-warning" style={{ marginTop: 10, width: 100, backgroundColor: '#23365e', color: 'white', border: ' 1px solid #23365e', fontFamily: 'Sofia Sans' }} type="submit" >Ekle</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div>{info}</div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <aside className="control-sidebar control-sidebar-dark">
                </aside>
                <Footer />
            </div>
        );
    }
}