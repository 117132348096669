import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import App from "../App";

function Giris() {
  const navigate = useNavigate();

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  var config = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  function handleKeyDown(event) {
    if (event.key === "Enter") {
      Login();
    }
  }
  function Login(event) {
    let variables = { username, password };
    event.preventDefault();

    const LOGIN_URL = "https://panel.polepuhu.com/phpcnnfolder/admncontrol.php";
    const LOGGEDIN_URL =
      "https://panel.polepuhu.com/phpcnnfolder/api/update_loggedin.php";

    axios.post(LOGIN_URL, variables, config).then((response) => {
      console.log(response);
      if ("hata" != response.data) {
        setError("Başarılı..!");
        let min = 10000000000,
          max = 99999999999;
        const token = Math.round(Math.random() * (max - min) + min);
        window.localStorage.setItem("token", token);
        window.localStorage.setItem("username", username);
        window.localStorage.setItem("type", response.data[0].type)
        navigate("/Anasayfa");
        axios
          .post(
            LOGGEDIN_URL,
            {
              loggedin: "1",
              username: username,
              password: password,
              token: token,
            },
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setError("Kullanıcı adı veya şifre hatalı..!");
      }
    });
  }

  return (
    <div
      class="d-flex justify-content-center"
      style={{
        backgroundColor: "white",
        height: "100vh",
      }}
    >
      <div className="register-box">
        <div className="register-logo" style={{ marginTop: "180px" }}>
          <img src="images/logo.png" style={{ marginTop: "0px" }} />
        </div>
        <div
          className="card"
          style={{
            marginTop: 50,
            border: "1px solid white",
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            padding: "1rem",
            background: "#f8f9fa",
          }}
        >
          <div
            className="card-body register-card-body"
            style={{ background: "#f8f9fa" }}
          >
            <p
              style={{
                fontWeight: "bold",
                color: "#37548d",
                fontSize: "18px",
              }}
              className="login-box-msg pull-left"
            >
              Yönetim Paneli
            </p>

            <form onSubmit={Login} >
            <div
              style={{
                backgroundColor: "white",
              }}
              className="input-group mb-3"
            >
              <input
                style={{
                  backgroundColor: "white",
                  color: "#37548d",
                }}
                type="text"
                className="form-control"
                placeholder="Kullanıcı Adı"
                name="KULLANICIADI"
                onChange={(e) => setUserName(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span
                    style={{
                      color: "#37548d",
                    }}
                    className="fas fa-user"
                  />
                </div>
              </div>
            </div>
              <div
                className="input-group mb-3"
                style={{ marginTop: 20, backgroundColor: "white" }}
              >
                <input
                  style={{
                    backgroundColor: "white",
                    color: "#37548d",
                  }}
                  type="password"
                  className="form-control"
                  placeholder="Şifre"
                  name="PAROLA"
                  onChange={(e) => setPassword(e.target.value)}

                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span
                      style={{
                        color: "#37548d",
                      }}
                      className="fas fa-lock"
                    />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3" style={{ marginTop: 30 }}>
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  style={{
                    background: "#37548d",
                    border: "none",
                    fontWeight: "bold",
                  }}
                >
                  Giriş Yap
                </button>
              </div>
            </form >
            <div
              className="center"
              style={{ marginTop: 10, textAlign: "center" }}
            >
              {error}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Giris;
