import React, { Component } from 'react'

export default class Footer extends Component {
  render() {
    return (
      <div>
        <footer class="main-footer">
    <strong>Copyright &copy; 2022 <a href="https://www.arasedas.com/">Aras Elektrik Dağıtım A.Ş.</a> </strong>
    Tüm hakları saklıdır.
    <div class="float-right d-none d-sm-inline-block">
    <b>Version</b> 1.0
    </div>
    </footer>
      </div>
    )
  }
}
