import React, { Component } from 'react'
import { useNavigate, Link } from "react-router-dom";
import Anasayfa from './pages/Anasayfa';
import SensorEkle from './pages/SensorEkle';
import GatewayEkle from './pages/GatewayEkle';
import GoogleFontLoader from 'react-google-font-loader';
import HomeIcon from '@mui/icons-material/Home';
import SensorsIcon from '@mui/icons-material/Sensors';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
export default class Menu extends Component {
  render() {
    const type = localStorage.getItem('type');
    return (
      <div>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Sofia Sans',
              weights: [400, '400i'],
            },
            {
              font: 'Roboto Condensed',
              weights: [400, 700],
            },
          ]}
          subsets={['cyrillic-ext', 'greek']}
        />
        <aside className="main-sidebar elevation-4" style={{ background: '#23365e', padddingLeft: '0.5rem', paddingTop: '0.5rem', }}>
          <Link to="../Anasayfa" class="brand-link logo-switch">
            <img src="../images/logo/green.png" alt="AdminLTE Logo" class="brand-image-xl logo-xs" style={{ maxHeight: '1.8rem' }} />
            <img src="../images/logo/logo2.png" alt="AdminLTE Logo" class="brand-image-xs logo-xl" style={{ minHeight: '55px' }} />
          </Link>
          <div className="sidebar">
            {/* <div className="info" style={{ color: 'white', textAlign: 'center', alignItems: 'center', marginTop: '2rem' }}>
              Yönetim Paneli
            </div> */}
            <nav className="mt-2">
              <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li className="nav-item menu-open">
                  {/* <a style={{ backgroundColor: 'white', color: '#1A142C', borderRadius: '5px', width: '85%', marginTop: '5rem', marginBottom: '0.5rem' }} className="nav-link active">
                    <i className="nav-icon fas fa-tachometer-alt" />
                    <p style={{ fontFamily: 'Sofia Sans' }}>
                      Yönetim
                    </p>
                  </a> */}
                  <ul className="nav nav-treeview">
                    <li style={{ marginBottom: '0.5rem', marginTop:'6rem' }} className="nav-item">
                      <Link to="../Anasayfa" className="nav-link" >
                        <div class="row">
                          <div class="col-2">                        <HomeIcon sx={{ color: 'white' }} />
                          </div>
                          <div class="col-10">                        <p style={{ color: 'white', fontFamily: 'Sofia Sans' }} >Anasayfa</p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    {/* { type == 1 && <>
                     <li className="nav-item">
                      <Link to="../SensorEkle" className="nav-link">
                        <div class="row">
                          <div class="col-2"><WifiTetheringIcon sx={{ color: 'white' }} />
                          </div>
                          <div class="col-10"> <p style={{ color: 'white', fontFamily: 'Sofia Sans' }} >Sensör Ekle</p>
                          </div>
                        </div>
                      </Link>
                    </li> 
                    <li className="nav-item">
                      <Link to="../GatewayEkle" className="nav-link">
                        <div class="row">
                          <div class="col-2"><SettingsRemoteIcon sx={{ color: 'white' }} />
                          </div>
                          <div class="col-10">                        <p style={{ color: 'white', fontFamily: 'Sofia Sans' }} >Gateway Ekle</p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    </>} */}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </aside>
      </div>
    )
  }
}
