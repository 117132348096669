import React from 'react';
import './Thermometer.css';

export const Thermometer = ({ value, text }) => {

    const batteryLevelStyle = {
        height: `${value}%`,
        backgroundColor: text <= 0 ? '#0996e8' : text <= 30 ? '#30b455' : text >= 30 ? '#e81309' : '#e81309',
    };


    
    return (
        <div className="thermometer" style={{"--my-color-var": (text <= 0 ? '#0996e8' : text <= 30 ? '#30b455' : text >= 30 ? '#e81309' : '#e81309')}} >
            <div className="mercury" >
                <div id="wrapper"  >
                    <div id="termometer" >
                        <div id="temperature" style={batteryLevelStyle} data-value={text}></div>
                        <div id="graduations" ></div>
                    </div>



                </div></div>
        </div>
    );

}

export default Thermometer;

