import React, { Component, useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Header from '../Header';
import Menu from '../Menu';
import Dashboard from '../Dashboard';
import Footer from '../Footer';
import axios from 'axios';
import BarChart from '../components/BarChart';
import { Line } from 'react-chartjs-2'
import 'moment-timezone';
import moment from 'moment';
import localization from 'moment/locale/tr';
import { Battery } from '../components/Battery';
import { Thermometer } from '../components/Thermometer';

export default function SensorGoruntule() {

    const { id } = useParams();
    console.log(id);
    const type = localStorage.getItem('type');

    const [sensordata, setSensorData] = useState({ data: [] });

    const [sensorid, setSensorID] = useState("");
    const [g_id, setG_ID] = useState("");
    const [ad, setAd] = useState("");
    const [enlem, setEnlem] = useState("");
    const [boylam, setBoylam] = useState("");
    const [egim_x, setEgimX] = useState("");
    const [egim_y, setEgimY] = useState("");
    const [egim_z, setEgimZ] = useState("");
    const [sicaklik, setSicaklik] = useState("");
    const [batarya, setBatarya] = useState("");
    const [gzaman, setGZaman] = useState("");
    const [uyari, setUyari] = useState("");
    const [uyari_varyok, setUyariVarYok] = useState("");
    const [info, setInfo] = useState("");
    const [cbskod, setCbsKod] = useState("");
    const [dtip, setDTip] = useState("");
    const [dcins, setDCins] = useState("");
    const [sensorad, setSensorAd] = useState("");


    const handleG_ID = (event) => { setG_ID(event.target.value); };
    const handleAd = (event) => { setAd(event.target.value); };
    const handleEnlem = (event) => { setEnlem(event.target.value); };
    const handleBoylam = (event) => { setBoylam(event.target.value); };
    const handleEgimX = (event) => { setEgimX(event.target.value); };
    const handleEgimY = (event) => { setEgimY(event.target.value); };
    const handleEgimZ = (event) => { setEgimZ(event.target.value); };
    const handleSicaklik = (event) => { setSicaklik(event.target.value); };
    const handleBatarya = (event) => { setBatarya(event.target.value); };
    const handleGZaman = (event) => { setGZaman(event.target.value); };
    const handleUyari = (event) => { setUyari(event.target.value); };
    const handleCbsKod = (event) => { setCbsKod(event.target.value); };
    const handleDTip = (event) => { setDTip(event.target.value); };
    const handleDCins = (event) => { setDCins(event.target.value); };

    const SENSOR_URL = "https://panel.polepuhu.com/phpcnnfolder/api/read_single_sensor.php?id=" + id;
    const UPDATE_SENSOR_URL = "https://panel.polepuhu.com/phpcnnfolder/api/update_sensor.php";
    const SENSOR_AYLIK_KAYIT_URL = "https://panel.polepuhu.com/phpcnnfolder/api/read_sensor_aylik_kayit.php?id=" + id;
    const UPDATE_SENSOR_CBSDTIPDCINS = "https://panel.polepuhu.com/phpcnnfolder/api/update_sensor_cbskoddtipdcins.php";

    const [sensoraylikdata, setSensorAylikData] = useState({ data: [] });

    useEffect(() => {

        //SENSOR_DATA
        axios.post(SENSOR_URL).then(response => {

            if ("hata" != response.data || 0 == response.data.length) {

                setSensorData(response.data);
                setSensorID(response.data.id);

                setG_ID(response.data.g_id);
                setAd(response.data.ad);
                setEnlem(response.data.enlem);
                setBoylam(response.data.boylam);
                setEgimX(response.data.egim_x);
                setEgimY(response.data.egim_y);
                setEgimZ(response.data.egim_z);
                setSicaklik(response.data.sicaklik);
                setBatarya(response.data.batarya);
                setGZaman(response.data.gzaman);
                setCbsKod(response.data.cbskod);
                setDTip(response.data.dtip);
                setDCins(response.data.dcins);
                setUyari(response.data.uyari);

                setSensorAd(response.data.ad);
                window.localStorage.setItem("ad", response.data.ad)

                const value = response.data.uyari;
                const value1 = value.charAt(0).toString();
                const value2 = value.charAt(1).toString();
                const value3 = value.charAt(2).toString();
                const value4 = value.charAt(3).toString();
                var returnvalue = "";

                if (1 == value1) { returnvalue += "+ Konum"; setUyariVarYok("1"); }
                if (1 == value2) { returnvalue += " + Eğim"; setUyariVarYok("1"); }
                if (1 == value3) { returnvalue += " + Sıcaklık"; setUyariVarYok("1"); }
                if (1 == value4) { returnvalue += " + Pil Gerilimi"; setUyariVarYok("1"); }

                if (0 == value1 && 0 == value2 && 0 == value3 && 0 == value4) {
                    returnvalue = "Yok";
                    setUyariVarYok("0");
                }
                setUyari(returnvalue);
                //
            }
        });

        //AYLIK_KAYIT
        axios.post(SENSOR_AYLIK_KAYIT_URL, {
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => {
                console.log("RESPONSE.DATA.LENGTH", response.data.length);

                if ("hata" != response.data) {
                    setSensorAylikData(response.data);
                    console.log("response.data.data.length = ", response.data.data.length);
                }
            })
            .catch(error => {
                console.log("SENSOR_AYLIK_DATA_ERROR", error);
            });

        //UPDATE SENSOR CBSKOD, DTIP, DCINS

        axios.post(UPDATE_SENSOR_CBSDTIPDCINS, {
            ad: ad
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => {
                console.log("UPDATED SENSOR CBSKOD, DTIP, DCINS");
            })
            .catch(error => {
                console.log(error);
            });

    }, []);


    //ReportCompose
    const ReportCompose = () => {
        var CsvString = '"sep=,"\r\n';
        /*Example data:
        [
    {
        "sicaklik": "23.9",
        "egim_x": "1.811",
        "egim_y": "90.53",
        "egim_z": "-89.2",
        "batarya": "3.9",
        "gzaman": "2023-06-03 10:04:31",
        "uyari": "000000",
        "biray": "2023-06-03 10:00:00"
    },
    {
        "sicaklik": "21.2",
        "egim_x": "1.811",
        "egim_y": "90.53",
        "egim_z": "-89.2",
        "batarya": "3.9",
        "gzaman": "2023-06-04 10:04:31",
        "uyari": "000000",
        "biray": "2023-06-03 10:00:00"
    },
    {
        "sicaklik": "19.1",
        "egim_x": "0.767",
        "egim_y": "90.49",
        "egim_z": "-90.2",
        "batarya": "3.8",
        "gzaman": "2023-06-05 10:04:31",
        "uyari": "000000",
        "biray": "2023-06-03 10:00:00"
    },
    {
        "sicaklik": "18.8",
        "egim_x": "1.811",
        "egim_y": "90.53",
        "egim_z": "-89.2",
        "batarya": "3.9",
        "gzaman": "2023-06-06 10:04:31",
        "uyari": "000000",
        "biray": "2023-06-03 10:00:00"
    }
]
        */
       CsvString += "SensorId" + "," + "GatewayId" + "," + "Sicaklik" + "," + "Egim X" + "," + "Egim Y" + "," + "Egim Z" + "," + "Batarya" + "," + "Guncelleme Zamani" + "," + "Uyari" + "\r\n";
         sensoraylikdata.data.map((item, key) => {
            CsvString += ad + "," + g_id + "," + "'"+ item.sicaklik +"'"+ "," + item.egim_x + "," + item.egim_y + "," + item.egim_z + "," + "'"+item.batarya + "'"+"," + item.gzaman + "," + item.uyari + "\r\n";
            })
            //encode in UTF-8
            CsvString = "data:application/csv;charset=utf-8," + encodeURIComponent(CsvString);
         var x = document.createElement("A");
         x.setAttribute("href", CsvString );
         x.setAttribute("download","SensorData.csv");
         document.body.appendChild(x);
         x.click();
    }

    // GRAFİK_ONCE_SONRA_DATA
    const returnsensoroncesonradata = () => {
        console.log("returnsensoroncesonradata.sensoraylikdata.length = ", sensoraylikdata.data.length);

        if (0 < sensoraylikdata.data.length) {

            console.log("sensoraylikdata.data", sensoraylikdata.data);
            console.log("sensoraylikdata.data[0].sicaklik = ", sensoraylikdata.data[0].sicaklik);
            console.log("RETURNING AYLIK DATA");

            if (2 > sensoraylikdata.data.length) {
                return (
                    <Line
                        data={{
                            labels: ['Önce', 'Sonra'],
                            datasets: [
                                {
                                    label: 'Sıcaklık',
                                    data: [`${(sensoraylikdata.data[0].sicaklik)}`, `${(sensordata.sicaklik)}`],
                                    backgroundColor: 'red',
                                    borderColor: 'red',
                                    borderWidth: 1,
                                },
                                {
                                    label: 'Eğim_X',
                                    data: [`${(sensoraylikdata.data[0].egim_x)}`, `${(sensordata.egim_x)}`],
                                    backgroundColor: 'green',
                                    borderColor: 'green',
                                },
                                {
                                    label: 'Eğim_Y',
                                    data: [`${(sensoraylikdata.data[0].egim_y)}`, `${(sensordata.egim_y)}`],
                                    backgroundColor: 'blue',
                                    borderColor: 'blue',
                                },
                                {
                                    label: 'Eğim_Z',
                                    data: [`${(sensoraylikdata.data[0].egim_z)}`, `${(sensordata.egim_z)}`],
                                    backgroundColor: 'orange',
                                    borderColor: 'orange',
                                },
                                {
                                    label: 'Batarya',
                                    data: [`${(sensoraylikdata.data[0].batarya)}`, `${(sensordata.batarya)}`],
                                    backgroundColor: 'yellow',
                                    borderColor: 'yellow',
                                },
                                {
                                    label: 'Uyarı',
                                    data: [`${(sensoraylikdata.data[0].uyari)}`, `${(sensordata.uyari)}`],
                                    backgroundColor: 'Purple',
                                    borderColor: 'Purple',
                                },
                            ],
                        }}
                        height={300}
                        width={300}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                yAxes: [
                                    {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                    },
                                ],
                            },
                            legend: {
                                labels: {
                                    fontSize: 25,
                                },
                            },
                        }}
                    />
                )
            }
            else if (0 == sensoraylikdata.data.length) {
                return (
                    <Line
                        data={{
                            labels: ['-'],
                            datasets: [
                                {
                                    label: 'Sıcaklık',
                                    data: [`${(0)}`],
                                    backgroundColor: 'red',
                                    borderColor: 'red',
                                    borderWidth: 1,
                                },
                                {
                                    label: 'Eğim_X',
                                    data: [`${(0)}`],
                                    backgroundColor: 'green',
                                    borderColor: 'green',
                                },
                                {
                                    label: 'Eğim_Y',
                                    data: [`${(0)}`],
                                    backgroundColor: 'blue',
                                    borderColor: 'blue',
                                },
                                {
                                    label: 'Eğim_Z',
                                    data: [`${(0)}`],
                                    backgroundColor: 'orange',
                                    borderColor: 'orange',
                                },
                                {
                                    label: 'Batarya',
                                    data: [`${(0)}`],
                                    backgroundColor: 'yellow',
                                    borderColor: 'yellow',
                                },
                                {
                                    label: 'Uyarı',
                                    data: [`${(0)}`],
                                    backgroundColor: 'Purple',
                                    borderColor: 'Purple',
                                },
                            ],
                        }}
                        height={300}
                        width={300}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                yAxes: [
                                    {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                    },
                                ],
                            },
                            legend: {
                                labels: {
                                    fontSize: 25,
                                },
                            },
                        }}
                    />
                )
            }
            else {
                return (

                    <Line
                        data={{
                            labels: ['Önce', 'Sonra'],
                            datasets: [
                                {
                                    label: 'Sıcaklık',
                                    data: [`${(sensoraylikdata.data[sensoraylikdata.data.length - 2].sicaklik)}`, `${(sensordata.sicaklik)}`],
                                    backgroundColor: 'red',
                                    borderColor: 'red',
                                    borderWidth: 1,
                                },
                                {
                                    label: 'Eğim_X',
                                    data: [`${(sensoraylikdata.data[sensoraylikdata.data.length - 2].egim_x)}`, `${(sensordata.egim_x)}`],
                                    backgroundColor: 'green',
                                    borderColor: 'green',
                                },
                                {
                                    label: 'Eğim_Y',
                                    data: [`${(sensoraylikdata.data[sensoraylikdata.data.length - 2].egim_y)}`, `${(sensordata.egim_y)}`],
                                    backgroundColor: 'blue',
                                    borderColor: 'blue',
                                },
                                {
                                    label: 'Eğim_Z',
                                    data: [`${(sensoraylikdata.data[sensoraylikdata.data.length - 2].egim_z)}`, `${(sensordata.egim_z)}`],
                                    backgroundColor: 'orange',
                                    borderColor: 'orange',
                                },
                                {
                                    label: 'Batarya',
                                    data: [`${(sensoraylikdata.data[sensoraylikdata.data.length - 2].batarya)}`, `${(sensordata.batarya)}`],
                                    backgroundColor: 'yellow',
                                    borderColor: 'yellow',
                                },
                                {
                                    label: 'Uyarı',
                                    data: [`${(sensoraylikdata.data[sensoraylikdata.data.length - 2].uyari)}`, `${(sensordata.uyari)}`],
                                    backgroundColor: 'Purple',
                                    borderColor: 'Purple',
                                },
                            ],
                        }}
                        height={300}
                        width={300}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                yAxes: [
                                    {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                    },
                                ],
                            },
                            legend: {
                                labels: {
                                    fontSize: 25,
                                },
                            },
                        }}
                    />
                )
            }

        }

    }

    // GRAFİK_AYLIK_DATA
    const returnsensoraylikdata = () => {
        console.log("sensoraylikdata.length = ", sensoraylikdata.data.length);

        if (0 < sensoraylikdata.data.length) {

            console.log("sensoraylikdata.data", sensoraylikdata.data);
            console.log("sensoraylikdata.data[0].sicaklik = ", sensoraylikdata.data[0].sicaklik);
            console.log("RETURNING AYLIK DATA");

            if (2 > sensoraylikdata.data.length) {
                return (
                    <Line
                        data={{
                            labels: [`${(moment(sensoraylikdata.data[0].gzaman).locale('TR', localization).format("DD-MMMM-YYYY"))}`, `${(moment(sensordata.gzaman).locale('TR', localization).format("DD-MMMM-YYYY"))}`],
                            datasets: [
                                {
                                    label: 'Sıcaklık',
                                    data: [`${(sensoraylikdata.data[0].sicaklik)}`, `${(sensordata.sicaklik)}`],
                                    backgroundColor: 'red',
                                    borderColor: 'red',
                                    borderWidth: 1,
                                },
                                {
                                    label: 'Eğim_X',
                                    data: [`${(sensoraylikdata.data[0].egim_x)}`, `${(sensordata.egim_x)}`],
                                    backgroundColor: 'green',
                                    borderColor: 'green',
                                },
                                {
                                    label: 'Eğim_Y',
                                    data: [`${(sensoraylikdata.data[0].egim_y)}`, `${(sensordata.egim_y)}`],
                                    backgroundColor: 'blue',
                                    borderColor: 'blue',
                                },
                                {
                                    label: 'Eğim_Z',
                                    data: [`${(sensoraylikdata.data[0].egim_z)}`, `${(sensordata.egim_z)}`],
                                    backgroundColor: 'orange',
                                    borderColor: 'orange',
                                },
                                {
                                    label: 'Batarya',
                                    data: [`${(sensoraylikdata.data[0].batarya)}`, `${(sensordata.batarya)}`],
                                    backgroundColor: 'yellow',
                                    borderColor: 'yellow',
                                },
                                {
                                    label: 'Uyarı',
                                    data: [`${(sensoraylikdata.data[0].uyari)}`, `${(sensordata.uyari)}`],
                                    backgroundColor: 'Purple',
                                    borderColor: 'Purple',
                                },
                            ],
                        }}
                        height={300}
                        width={300}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                yAxes: [
                                    {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                    },
                                ],
                            },
                            legend: {
                                labels: {
                                    fontSize: 25,
                                },
                            },
                        }}
                    />
                )
            }
            else if (0 == sensoraylikdata.data.length) {
                return (
                    <Line
                        data={{
                            labels: ["-"],
                            datasets: [
                                {
                                    label: 'Sıcaklık',
                                    data: [`${(0)}`],
                                    backgroundColor: 'red',
                                    borderColor: 'red',
                                    borderWidth: 1,
                                },
                                {
                                    label: 'Eğim_X',
                                    data: [`${(0)}`],
                                    backgroundColor: 'green',
                                    borderColor: 'green',
                                },
                                {
                                    label: 'Eğim_Y',
                                    data: [`${(0)}`],
                                    backgroundColor: 'blue',
                                    borderColor: 'blue',
                                },
                                {
                                    label: 'Eğim_Z',
                                    data: [`${(0)}`],
                                    backgroundColor: 'orange',
                                    borderColor: 'orange',
                                },
                                {
                                    label: 'Batarya',
                                    data: [`${(0)}`],
                                    backgroundColor: 'yellow',
                                    borderColor: 'yellow',
                                },
                                {
                                    label: 'Uyarı',
                                    data: [`${(0)}`],
                                    backgroundColor: 'Purple',
                                    borderColor: 'Purple',
                                },
                            ],
                        }}
                        height={300}
                        width={300}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                yAxes: [
                                    {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                    },
                                ],
                            },
                            legend: {
                                labels: {
                                    fontSize: 25,
                                },
                            },
                        }}
                    />
                )
            }
            else {
                return (
                    <Line
                        data={{
                            labels: Object.values(sensoraylikdata.data).flatMap((item) => moment(item.gzaman).locale('TR', localization).format("DD-MMMM-YYYY")),
                            datasets: [
                                {
                                    label: 'Sıcaklık',
                                    data: Object.values(sensoraylikdata.data).flatMap((item) => item.sicaklik),
                                    backgroundColor: 'red',
                                    borderColor: 'red',
                                    borderWidth: 1,
                                },
                                {
                                    label: 'Eğim_X',
                                    data: Object.values(sensoraylikdata.data).flatMap((item) => item.egim_x),
                                    backgroundColor: 'green',
                                    borderColor: 'green',
                                },
                                {
                                    label: 'Eğim_Y',
                                    data: Object.values(sensoraylikdata.data).flatMap((item) => item.egim_y),
                                    backgroundColor: 'blue',
                                    borderColor: 'blue',
                                },
                                {
                                    label: 'Eğim_Z',
                                    data: Object.values(sensoraylikdata.data).flatMap((item) => item.egim_z),
                                    backgroundColor: 'orange',
                                    borderColor: 'orange',
                                },
                                {
                                    label: 'Batarya',
                                    data: Object.values(sensoraylikdata.data).flatMap((item) => item.batarya),
                                    backgroundColor: 'yellow',
                                    borderColor: 'yellow',
                                },
                                {
                                    label: 'Uyarı',
                                    data: Object.values(sensoraylikdata.data).flatMap((item) => item.uyari),
                                    backgroundColor: 'Purple',
                                    borderColor: 'Purple',
                                },
                            ],
                        }}
                        height={300}
                        width={300}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                yAxes: [
                                    {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                    },
                                ],
                            },
                            legend: {
                                labels: {
                                    fontSize: 25,
                                },
                            },
                        }}
                    />
                )
            }
        }

    }


    const handleSubmit = async () => {

        if ("Yok" == uyari) {
            setUyari("0000");
        } else if ("0000" == uyari) {
            setUyari("0000");
        }
        else {
            setUyari("0000");
        }

        if ("" != g_id) {
            axios.post(UPDATE_SENSOR_URL, {
                id: id,
                g_id: g_id,
                ad: ad,
                enlem: enlem,
                boylam: boylam,
                egim_x: egim_x,
                egim_y: egim_y,
                egim_z: egim_z,
                sicaklik: sicaklik,
                batarya: batarya,
                gzaman: gzaman,
                uyari: uyari,
                cbskod: cbskod,
                dtip: dtip,
                dcins: dcins
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
                .then(response => {
                    console.log(response.data);
                    setInfo("Güncellendi");
                })
                .catch(error => {
                    console.log(error);
                    setInfo(error);
                });
        }
    }


    const token = window.localStorage.getItem("token");
    console.log("USER TOKEN SENSORGORUNTULE", token);

    if (null != token) {
        return (
            <div class="wrapper">
                <Header />
                <Menu />
                <div className="content-wrapper">
                    <br></br>
                    <section class="content">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <h3 class="card-title">Sensör Görüntüle</h3>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div className="col-md-6">
                                                        <div class="row">
                                                            <div className="content">
                                                                <div className="container-fluid">
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>GatewayID:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className="form-control" onChange={handleG_ID} value={g_id} placeholder={sensordata.g_id} name="GATEWAY_ID" disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>NodeID:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className="form-control" onChange={handleAd} value={ad} placeholder={sensordata.ad} name="AD" disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>Enlem:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className="form-control" onChange={handleEnlem} value={enlem} placeholder={sensordata.enlem} name="ENLEM" disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>Boylam:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className="form-control" onChange={handleBoylam} value={boylam} placeholder={sensordata.boylam} name="BOYLAM" disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>Eğim_X:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className="form-control" onChange={handleEgimX} value={egim_x} placeholder={sensordata.egim_x} name="EGIMX" disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>Eğim_Y:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className="form-control" onChange={handleEgimY} value={egim_y} placeholder={sensordata.egim_y} name="EGIMY" disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>Eğim_Z:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className="form-control" onChange={handleEgimZ} value={egim_z} placeholder={sensordata.egim_z} name="EGIMZ" disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>Sıcaklık:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className="form-control" onChange={handleSicaklik} value={sicaklik} placeholder={sensordata.sicaklik} name="SICAKLIK" disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>Batarya Gerilimi:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className="form-control" onChange={handleBatarya} value={batarya} placeholder={sensordata.batarya} name="BATARYAGERILIMI" disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>Cbs Kodu:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    type == "1" &&
                                                                                    <input type="text" className="form-control" onChange={handleCbsKod} value={cbskod} placeholder={sensordata.cbskod} name="CBSKODU" />
                                                                                }
                                                                                {
                                                                                    type == "0" &&
                                                                                    <input type="text" className="form-control" onChange={handleCbsKod} value={cbskod} placeholder={sensordata.cbskod} name="CBSKODU" disabled />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>Direk Cinsi:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    type == "1" &&
                                                                                    <select className="custom-select form-control" onChange={handleDCins} value={dcins} placeholder={sensordata.dcins} name="DIREKCINSI">
                                                                                        <option>Demir Direk</option>
                                                                                        <option>Beton Direk</option>
                                                                                        <option>Ağaç Direk</option>
                                                                                    </select>
                                                                                }
                                                                                {
                                                                                    type == "0" &&
                                                                                    <select className="custom-select form-control" onChange={handleDCins} value={dcins} placeholder={sensordata.dcins} name="DIREKCINSI" disabled>
                                                                                        <option>Demir Direk</option>
                                                                                        <option>Beton Direk</option>
                                                                                        <option>Ağaç Direk</option>
                                                                                    </select>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>Direk Tipi:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    type == "1" &&
                                                                                    <input type="text" className="form-control" onChange={handleDTip} value={dtip} placeholder={sensordata.dtip} name="DIREKTIPI" />
                                                                                }
                                                                                {
                                                                                    type == "0" &&
                                                                                    <input type="text" className="form-control" onChange={handleDTip} value={dtip} placeholder={sensordata.dtip} name="DIREKTIPI" disabled />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>Güncelleme Zamanı:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    type == "1" &&
                                                                                    <input type="text" className="form-control" onChange={handleGZaman} value={moment(gzaman).format('DD-MM-YYYY HH:mm:ss')} placeholder={moment(sensordata.gzaman).format('DD-MM-YYYY HH:mm:ss')} name="GUNCELLEMEZAMANİ" />
                                                                                }
                                                                                {
                                                                                    type == "0" &&
                                                                                    <input type="text" className="form-control" onChange={handleGZaman} value={moment(gzaman).format('DD-MM-YYYY HH:mm:ss')} placeholder={moment(sensordata.gzaman).format('DD-MM-YYYY HH:mm:ss')} name="GUNCELLEMEZAMANİ" disabled />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label>Uyarı:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div class="input-group-text">
                                                                                        <span class="fas fa-edit"></span>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    type == "1" &&
                                                                                    <input type="text" className="form-control" onChange={handleUyari} value={uyari} placeholder={sensordata.uyari} name="UYARI" />
                                                                                }
                                                                                {
                                                                                    type == "0" &&
                                                                                    <input type="text" className="form-control" onChange={handleUyari} value={uyari} placeholder={sensordata.uyari} name="UYARI" disabled />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {type == "1" &&
                                                                        <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <div class="input-group">
                                                                                <button class="btn btn-warning" style={{ marginTop: 10, width: 100 }}>Güncelle</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">

                                                        <div class="card">
                                                            <div class="card-header">
                                                                <h3 class="card-title">Sensör Grafiği - Son Alınan 2 Değerler</h3>

                                                                <div class="card-tools">
                                                                    <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
                                                                    <button type="button" class="btn btn-tool" data-card-widget="remove"><i class="fas fa-times"></i></button>
                                                                </div>
                                                            </div>
                                                            <div class="card-body">
                                                                <div class="row">
                                                                    <div>

                                                                        {returnsensoroncesonradata()}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-md-12">
                                                            <div class="card" style={{ marginLeft: -7, marginRight: -7 }}>
                                                                <div class="card-header">
                                                                    <h3 class="card-title">Sensör Grafiği - 1 Aylık Değerler</h3>

                                                                    <div class="card-tools">
                                                                        <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
                                                                        <button type="button" class="btn btn-tool" data-card-widget="remove"><i class="fas fa-times"></i></button>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body">
                                                                    <div class="row">
                                                                        <div>
                                                                            {returnsensoraylikdata()}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="card" style={{
                                                            padding: "2rem",
                                                            paddingTop: "0rem",
                                                        }} >
                                                            <div className="row" style={{
                                                                marginTop: "3rem",

                                                                justifyContent: "center",
                                                                display: "flex"
                                                            }} >

                                                                <div className="col-md-3">   <Battery text={sensordata.batarya} value={(sensordata.batarya / 4.2) * 100} /></div>
                                                                <div className="col-md-3">        <Thermometer text={sensordata.sicaklik} value={((sensordata.sicaklik + 20) / 80) * 100} /> </div></div>

                                                        </div>

                                                        <div class="card" style={{
                                                            padding: "1rem",
                                                            paddingTop: "0rem",
                                                        }} >
                                                            <div className="row" style={{
                                                                marginTop: "1rem",

                                                                justifyContent: "center",
                                                                display: "flex"
                                                            }} >

                                                                {/* Button */}
                                                                <div className="col-md-6">   <button type="button" onClick={ReportCompose} class="btn btn-block btn-primary btn-lg">Rapor İndir</button></div>

                                                                
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div>{info}</div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <aside className="control-sidebar control-sidebar-dark">
                </aside>

                <Footer />
            </div>

        );
    }
}