import { useState } from 'react'
import React from 'react'
import './Battery.css'



export const Battery = ({ value, text }) => {
    const batteryLevel = Math.floor(value); // 0 to 100
    const batteryLevelStyle = {
        height: `${value}%`,
        backgroundColor: value >= 70 ? '#30b455' : value >= 50 ? '#efaf13' : '#e81309',
    };

    return (
        <div>
            <div className="battery">
                <div className="battery-level" style={batteryLevelStyle}>   <div className="battery-percentage"> {text}V</div></div>


            </div>
        </div>
    );
};