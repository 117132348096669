import React, { Component } from 'react'
import GoogleFontLoader from 'react-google-font-loader';
import { useNavigate, Link } from 'react-router-dom'

export default function Header() {

  const navigate = useNavigate();

  const username = window.localStorage.getItem("username");
  console.log("USER NAME HEADER", username);

  const handleCikisYap = () => { window.localStorage.clear(); navigate('/'); };

  return (
    <div>
      <GoogleFontLoader
        fonts={[
          {
            font: 'Sofia Sans',
            weights: [400, '400i'],
          },
          {
            font: 'Roboto Condensed',
            weights: [400, 700],
          },
        ]}
        subsets={['cyrillic-ext', 'greek']}
      />
      <nav style={{ backgroundColor: '#23365e' }} className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">

          <li className="nav-item d-none d-sm-inline-block">
            <Link to="../Anasayfa" className="nav-link">
              <p style={{ color: 'white', fontFamily: 'Sofia Sans' }}>Anasayfa</p>
            </Link>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <div className="navbar-search-block">
              <form className="form-inline">
                <div className="input-group input-group-sm">
                  <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                  <div className="input-group-append">
                    <button className="btn btn-navbar" type="submit">
                      <i className="fas fa-search" />
                    </button>
                    <button className="btn btn-navbar" type="button" data-widget="navbar-search">
                      <i className="fas fa-times" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>
          <li className="nav-item"><a className="nav-link" style={{ color: 'white', fontFamily: 'Sofia Sans' }}><i className="fas fa-user" /> {username}</a></li>
          <li className="nav-item">
            <a style={{ color: 'white' }} className="nav-link" data-widget="fullscreen" role="button">
              <i className="fas fa-expand-arrows-alt" />
            </a>
          </li>
          <li className="nav-item"><a className="nav-link" href="#" style={{ color: '#ff0000', fontFamily: 'Sofia Sans' }} onClick={handleCikisYap}>Çıkış Yap</a></li>
        </ul>
      </nav>
    </div>
  )
}

