import React, { Component, useState, useRef, useEffect } from 'react'
import Header from '../Header';
import Menu from '../Menu';
import Dashboard from '../Dashboard';
import Footer from '../Footer';
import axios from 'axios';
import 'moment-timezone';
import moment from 'moment';
import localization from 'moment/locale/tr';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { makeStyles } from '@mui/styles';
import GoogleFontLoader from 'react-google-font-loader';

import LanguageIcon from '@mui/icons-material/Language';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import RampLeftIcon from '@mui/icons-material/RampLeft';



const useStyles = makeStyles({
    //change placeholder color in inputTwo
    inputTwo: {
        border: '1px solid #D1D8E4',
        borderRadius: '1rem',
        '&::placeholder': {
            color: '#8D99B8',
        },
    },

});
export default function GatewayEkle() {


    const ADDGATEWAY_URL = "https://panel.polepuhu.com/phpcnnfolder/api/insert_gateway.php";

    const [g_id, setG_ID] = useState("");
    const [ad, setAd] = useState("");
    const [enlem, setEnlem] = useState("");
    const [boylam, setBoylam] = useState("");
    const [cbskod, setCbsKod] = useState("");
    const [dtip, setDTip] = useState("");
    const [dcins, setDCins] = useState("");
    const [info, setInfo] = useState("");

    const handleG_ID = (event) => { setG_ID(event.target.value); };
    const handleAd = (event) => { setAd(event.target.value); };
    const handleEnlem = (event) => { setEnlem(event.target.value); };
    const handleBoylam = (event) => { setBoylam(event.target.value); };
    const handleCbsKod = (event) => { setCbsKod(event.target.value); };
    const handleDTip = (event) => { setDTip(event.target.value); };
    const handleDCins = (event) => { setDCins(event.target.value); };

    const handleSubmit = async () => {

        if ("" != g_id) {
            axios.post(ADDGATEWAY_URL, {
                g_id: g_id,
                ad: ad,
                enlem: enlem,
                boylam: boylam,
                cbskod: cbskod,
                dtip: dtip,
                dcins: dcins,
                gzaman: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
                .then(response => {
                    console.log(response.data);
                    setInfo("Eklendi");
                })
                .catch(error => {
                    console.log(error);
                    setInfo(error);
                });
        }
    }
    const token = window.localStorage.getItem("token");
    console.log("USER TOKEN GATEWAYEKLE", token);
    const classes = useStyles();

    if (null != token) {
        return (
            <div class="wrapper">
                <GoogleFontLoader
                    fonts={[
                        {
                            font: 'Sofia Sans',
                            weights: [400, '400i'],
                        },
                        {
                            font: 'Roboto Condensed',
                            weights: [400, 700],
                        },
                    ]}
                    subsets={['cyrillic-ext', 'greek']}
                />
                <Header />
                <Menu />
                <div style={{
                    backgroundColor: "#F0F2F6",
                    height: "100vh",
                }} className="content-wrapper">
                    <br></br>
                    <section class="content">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <h3 style={{ fontWeight: 'bold', fontSize: '20px', fontFamily: 'Sofia Sans' }} class="card-title">Gateway Ekle</h3>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div className="col-md-12">
                                                        <div class="row">
                                                            <div className="content">
                                                                <div className="container-fluid">

                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-6">
                                                                            <label style={{ color: '#001747', fontFamily: 'Sofia Sans' }}>Gateway ID:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div style={{ backgroundColor: '#D3E5FD' }} class="input-group-text">
                                                                                        <PodcastsIcon />                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className={classes.inputTwo + ' form-control'} onChange={handleG_ID} value={g_id} placeholder="GatewayID" name="GATEWAYID" />
                                                                            </div>
                                                                        </div><div className="col-lg-6">
                                                                            <label style={{ color: '#001747', fontFamily: 'Sofia Sans' }}>Gateway Adı:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div style={{ backgroundColor: '#D3E5FD' }} class="input-group-text">
                                                                                        <PodcastsIcon />                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className={classes.inputTwo + ' form-control'} onChange={handleAd} value={ad} placeholder="Gateway Adı" name="GATEWAYADI" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-6">
                                                                            <label style={{ color: '#001747', fontFamily: 'Sofia Sans' }}>Enlem:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div style={{ backgroundColor: '#D3E5FD' }} class="input-group-text">
                                                                                        <LanguageIcon />                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className={classes.inputTwo + ' form-control'} onChange={handleEnlem} value={enlem} placeholder="Enlem" name="ENLEM" />
                                                                            </div>
                                                                        </div> <div className="col-lg-6">
                                                                            <label style={{ color: '#001747', fontFamily: 'Sofia Sans' }}>Boylam:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div style={{ backgroundColor: '#D3E5FD' }} class="input-group-text">
                                                                                        <LanguageIcon />                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className={classes.inputTwo + ' form-control'} onChange={handleBoylam} value={boylam} placeholder="Boylam" name="BOYLAM" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-6">
                                                                            <label style={{ color: '#001747', fontFamily: 'Sofia Sans' }}>Cbs Kodu:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div style={{ backgroundColor: '#D3E5FD' }} class="input-group-text">
                                                                                        <QrCode2Icon />                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className={classes.inputTwo + ' form-control'} onChange={handleCbsKod} value={cbskod} placeholder="Cbs Kodu" name="CBSKODU" />
                                                                            </div>
                                                                        </div> <div className="col-lg-6">
                                                                            <label style={{ color: '#001747', fontFamily: 'Sofia Sans' }}>Direk Cinsi:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div style={{ backgroundColor: '#D3E5FD' }} class="input-group-text">
                                                                                        <RampLeftIcon />
                                                                                    </div>
                                                                                </div>
                                                                                <select className="custom-select form-control" onChange={handleDCins} value={dcins} placeholder="Direk Cinsi" name="DIREKCINSI">
                                                                                    <option>Demir Direk</option>
                                                                                    <option>Beton Direk</option>
                                                                                    <option>Ağaç Direk</option>
                                                                                </select>                                                                        </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <label style={{ color: '#001747', fontFamily: 'Sofia Sans' }}>Direk Tipi:</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-append">
                                                                                    <div style={{ backgroundColor: '#D3E5FD' }} class="input-group-text">
                                                                                        <RampLeftIcon />                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" className={classes.inputTwo + ' form-control'} onChange={handleDTip} value={dtip} placeholder="Direk Tipi" name="DIREKTIPI" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-lg-12">
                                                                            <div class="input-group">
                                                                                <button style={{ marginTop: 10, width: 100, backgroundColor: '#23365e', color: 'white', border: ' 1px solid #23365e' }} class="btn btn-warning" >Ekle</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div>{info}</div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <aside className="control-sidebar control-sidebar-dark">
                </aside>
                <Footer />
            </div>
        );
    }
}
