import logo from './logo.svg';
import './App.css';
import Header from './Header';
import Menu from './Menu';
import Dashboard from './Dashboard';
import Footer from './Footer';
import BarChart from "./components/BarChart";
import LineChart from "./components/LineChart";
import PieChart from "./components/PieChart";
import { UserData } from "./Data";
import { useState } from 'react';

function App() {

  return (

    <div class="wrapper">
      <Header />
     

      <Footer />
    </div>

  );
}

export default App;
